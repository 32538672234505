import {Authenticator} from "@aws-amplify/ui-react";
import {AppRoutes} from "./routing/AppRoutes";
import React from "react";

function App() {
  return <>
    <Authenticator.Provider>
      <AppRoutes />
    </Authenticator.Provider>
  </>
}

export default App;
