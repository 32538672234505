import React, {useState} from "react";
import {Habit} from "../global";
import {Button, Form} from "react-bootstrap";
import {Card, Checkbox, Numberbox, Textbox} from "@wradetech/react-components";

type Props = {
    onSubmitted: (habit: Habit) => void;
    habit?: Habit
}

export const HabitForm = (props: Props) => {

    // @ts-ignore
    const [habit, setHabit] = useState<Habit>(props.habit ? props.habit : {})
    
    const onSubmitHandler = () => {
        props.onSubmitted(habit)
    }
    
    const handleTextChange = (e: any) => {
        setHabit({
            ...habit,
            [e.target.name]: e.target.value
        });
    }

    const handleCheckBoxChange = (e: any) => {
        setHabit({
            ...habit,
            [e.target.name]: e.target.checked
        });
    }
    
    
    return <>
        <Card header="Habit">
            <Form>
                <Textbox value={habit.name} onChange={handleTextChange} name="name" label="Name"/>
                <Checkbox name="monday" label="Monday" onChange={handleCheckBoxChange} isChecked={habit.monday}/>
                <Checkbox name="tuesday" label="Tuesday" onChange={handleCheckBoxChange} isChecked={habit.tuesday}/>
                <Checkbox name="wednesday" label="Wednesday" onChange={handleCheckBoxChange}
                          isChecked={habit.wednesday}/>
                <Checkbox name="thursday" label="Thursday" onChange={handleCheckBoxChange} isChecked={habit.thursday}/>
                <Checkbox name="friday" label="Friday" onChange={handleCheckBoxChange} isChecked={habit.friday}/>
                <Checkbox name="saturday" label="Saturday" onChange={handleCheckBoxChange} isChecked={habit.saturday}/>
                <Checkbox name="sunday" label="Sunday" onChange={handleCheckBoxChange} isChecked={habit.sunday}/>
                <Numberbox name="order" label="Order" value={habit.order} handleChange={handleTextChange}/>
                <Button variant='primary' type='button' onClick={onSubmitHandler}>Save</Button>


            </Form>
        </Card>
    </>
}