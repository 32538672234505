import {Habit} from "../global";
import {Button, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {HabitForm} from "./HabitForm";

type Props = {
    habits: Habit[]
    habitRemoved: (habit: Habit) => void;
    habitUpdated: (habit: Habit) => void;
}
export const Habits = (props: Props) => {

    const [show, setShow] = useState(false);
    const [habit, setHabit] = useState<Habit>();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    const openEdit = (habit: Habit) => {
        
        setHabit(habit)
        
        handleShow();
    }

    const onUpdated = (habit: Habit) => {
        props.habitUpdated(habit);
    }
    
    return <>
        <table className="table">
            <thead>
                <tr>
                    <th>Habit</th>
                </tr>
            </thead>
            <tbody>
                {props.habits.sort((a, b) => a.order - b.order).map(x => 
                    <tr>
                        <td>{x.name}</td>
                        <td>                    
                            <Button variant='primary' type='button' onClick={() => props.habitRemoved(x)}>Remove</Button>
                            <Button variant="primary" onClick={() => openEdit(x)}>
                                Edit                            
                            </Button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>


        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body><HabitForm habit={habit!} onSubmitted={onUpdated}/></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
