import React, {FC} from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import {useAuthenticator} from '@aws-amplify/ui-react'
import Landing from "../pages/Landing";
import {RoutineView} from "../pages/RoutineView";
import {CreateRoutineView} from "../pages/CreateRoutineView";
import {MasterLayout} from "../Layout/MasterLayout";



const AppRoutes: FC = () => {
    const {authStatus} = useAuthenticator((context) => [context.authStatus])

    return (
        <BrowserRouter>
            <Routes>
                {authStatus === 'authenticated' ? (
                    <>
                        <Route element={<MasterLayout  />}>
                            <Route path='/dashboard/routine' element={<RoutineView />} />
                        </Route>
                        <Route path='/' element={<CreateRoutineView />} />

                    </>
                ) : (
                    <>
                        <Route path='/' element={<Landing  />} />
                        <Route path='/*' element={<Landing />} />
                    </>
                )}
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
