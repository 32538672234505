import {useEffect, useState} from "react";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {HabitForm} from "../components/HabitForm";
import {Habit, Routine} from "../global";
import {Habits} from "../components/Habits";
import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";

export const RoutineView = () => {
    
    const [routine, setRoutine] = useState<Routine>();

    useEffect(() => {

        const fetch = async () => {
            const client = new ApiClient();

            const data = await client.get('routine')
            
            setRoutine(data)
        }
        
        fetch();
        
    }, []);
    
    if(!routine) return <Loading/>

    const habitCreated = async (habit: Habit) => {
        habit.id = uuidv4();

        routine.habits.push(habit);
        
        setRoutine({...routine})

        toast.info(`${habit.name} created`);
        
        const client = new ApiClient();
        
        await client.put('routine', routine)
    };

    const habitRemoved = async (habit: Habit) => {

        routine.habits = routine.habits.filter(x => x.id !== habit.id);

        setRoutine({...routine})

        toast.info(`${habit.name} removed`);

        const client = new ApiClient();

        await client.put('routine', routine)
    };

    const habitUpdated = async (habit: Habit) => {

        routine.habits = routine.habits.filter(x => x.id !== habit.id);

        routine.habits.push(habit);
        
        setRoutine({...routine})

        toast.info(`${habit.name} updated`);

        const client = new ApiClient();

        await client.put('routine', routine)
    };

    // @ts-ignore
    return <>
        <p>{routine.name}</p>
        
        <HabitForm onSubmitted={habitCreated}/>
        <Habits habits={routine.habits} habitRemoved={habitRemoved} habitUpdated={habitUpdated}/>
    </>
}