import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Amplify} from "aws-amplify";
import 'bootstrap/dist/css/bootstrap.min.css';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId:  process.env.REACT_APP_CLIENT_ID!,
            userPoolId:  process.env.REACT_APP_USER_POOL!,
            loginWith: { // Optional
                oauth: {
                    domain: process.env.REACT_APP_USER_POOL_DOMAIN!,
                    scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: [process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_IN!],
                    redirectSignOut: [process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_IN!],
                    responseType: 'code',
                }
            }
        }
    }
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
