import {
    fetchAuthSession,
} from 'aws-amplify/auth';

import axios from 'axios'

const getToken = async () => {
    const session = await fetchAuthSession()
    return`Bearer ${session.tokens?.idToken}`

}

export class ApiClient {
    
    async get(endpoint: string) {
        const token = await getToken();

        const response = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
            headers: {Authorization: token},
        })

        console.log('Response', response)
        
        return response.data
    }

    async post(endpoint: string, data?: any) {
        const token = await getToken();
        
        const response = await axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, data, {
            headers: {Authorization: token},
        })
        
        console.log('Response', response)
        
        return response
    }

    async put(endpoint: string, data?: any) {
        const token = await getToken();

        const response = await axios.put(`${process.env.REACT_APP_API_URL}${endpoint}`, data, {
            headers: {Authorization: token},
        })

        console.log('Response', response)

        return response
    }
}
