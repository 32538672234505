import React from 'react';
import {Login} from "@wradetech/react-components";

const LandingPage = () => {
    
    return (
        <>
            <Login text="Habit builder" title="Pathway"/>
        </>

    )
}

export default LandingPage;
