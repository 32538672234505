import React, {useEffect, useState} from "react";
import {
    signOut,
    fetchUserAttributes
} from 'aws-amplify/auth';
import {Header} from "@wradetech/react-components";
import { Content } from "./Content";

export const MasterLayout = () => {
    
    const [username, setUsername] = useState<string>();
    
    useEffect(() => {
        getLoggedInUser();
    }, []);

    const getLoggedInUser = async () => {
        const attributesOutput = await fetchUserAttributes();

        const username = attributesOutput.email;

        setUsername(username)
    }

    const logout = async () => {
        await signOut()
    }
    
    return <>
        <Header header={"Pathway"} userName={username!} logout={logout} links={[]}/>
        <div className="container-fluid">
            <div className="row">
                <Content/>
            </div>
        </div>


    </>
}