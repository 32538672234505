import {Button, Card, Container, Form, Spinner} from 'react-bootstrap'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {Routine} from "../global";

type Props = {
    onCreating?: () => void
    onCreated?: (routine: Routine) => void
}

export function CreateRoutineView(props: Props) {
    const [isCreatingRoutine, setIsCreatingRoutine] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const navigate = useNavigate()

    const [routineName, setRoutineName] = useState('')


    useEffect(() => {
        const loadData = async () => {
            const api = new ApiClient();

            const response = await api.get("routine/userHasRoutine");

            if(response)
            {
                navigate('/dashboard/routine')
            }

            setIsLoading(false)
        }

        loadData();

    },);

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setIsCreatingRoutine(true)

        const api = new ApiClient();

        await api.post('Routine', {routineName: routineName});

        setIsCreatingRoutine(false)

        navigate('/dashboard/routine')
    }

    if(isLoading) return <Loading/>

    return (
        <div>
            <Card style={{marginTop: '20px', marginBottom: '20px'}}>
                <Card.Header as='h5'>Create Routine</Card.Header>
                <Card.Body>
                    <Container>
                        {isCreatingRoutine && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <Spinner animation='border' role='status'></Spinner>
                            </div>
                        )}

                        <Form onSubmit={onSubmitHandler}>
                            <Form.Group className='mb-3' controlId='formRoutine'>
                                <Form.Label>Routine Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter Routine Name'
                                    onChange={(e) => setRoutineName(e.target.value)}
                                />
                            </Form.Group>
                            <Button variant='primary' type='submit'>
                                Submit
                            </Button>
                        </Form>
                    </Container>
                </Card.Body>
            </Card>
        </div>
    )
}
